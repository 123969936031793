<template>
  <div class="white-list">
    <div class="header-top">
      <div class="flex-item">
        <el-select v-model="params.site_id">
          <el-option
            v-for="item in siteList"
            :key="item.site_id"
            :value="item.site_id"
            :label="item.proxy_name"
          ></el-option>
        </el-select>
        <el-input v-model="params.goods_id" placeholder="请输入商品ID"></el-input>
        <el-button @click="reset">重置</el-button>
        <el-button type="primary" @click="getList">查询</el-button>
      </div>
      <el-button type="primary" @click="addVisible = true">添加白名单商品</el-button>
    </div>
    <DragTable :header="header" :list-query="list">
      <template #state="{row}">{{state[row.state]}}</template>
      <template #goods_link="{row}"><a :href="row.goods_link" target="_blank">{{row.goods_link}}</a></template>
      <template #ctl="{ row }"
        ><el-button type="text" @click="del(row)">删除</el-button></template
      >
    </DragTable>
    <div class="table-footer">
      <span></span>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.page"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="params.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="params.total"
      ></el-pagination>
    </div>
    <go-dialog v-model="addVisible">
      <div>
        <div class="item">
          <span>选择站点</span>
          <div>
            <el-radio
            v-model="info.site_id"
            v-for="item in siteList"
            :key="item.site_id"
            :label="item.site_id"
            >{{item.proxy_name}}</el-radio>
          </div>
        </div>
        <div class="item">
          <span>添加商品</span>
          <el-input v-model="info.goods_id" placeholder="请输入原站商品ID"></el-input>
        </div>
        <div class="item">
          <span>原站链接</span>
          <el-input v-model="info.goods_link" placeholder="请输入原站链接（选填）"></el-input>
        </div>
      </div>
      <div class="table-footer">
        <el-button type="primary" @click="save">保存</el-button>
        <el-button type="primary" @click="addVisible = false">取消</el-button>
      </div>
    </go-dialog>
  </div>
</template>
<script>
import { defineComponent, reactive, ref, watch } from '@vue/composition-api'

export default defineComponent({
  setup(props, {root}) {
    const addVisible = ref(false)
    const params = reactive({
      site_id: 19,
      goods_id: "",
      page: 1,
      limit: 10,
      total: 0,
    })
    const info =  reactive({
      goods_id: '',
      site_id: '',
      goods_link: ''
    })
    const list = reactive({
      data: []
    })
    const state = {
      1: '白名单'
    }
    const header = [
      { name: "商品站点", value: "site_name" },
      { name: "商品ID", value: "goods_id" },
      { name: "商品链接", slot: "goods_link" },
      { name: "状态", slot: "state" },
      { name: "操作时间", value: "operation_at" },
      { name: '编辑', slot: 'ctl' }
    ]
    const siteList = ref([])
    watch(addVisible,val=>{
      if(!val) {
        for(let key in info) {
          info[key] = ''
        }
      }
    })
    const getList = () => {
      if(params.goods_id) {
        params.goods_id = params.goods_id.trim()
      }
      root.$axios
        .get("/orderSafe/platformBan/goodsWhite/list", {
          params,
        })
        .then((res) => {
          list.data = res.data.list;
          params.total = res.data.total;
        })
    }
    getList()
    const getSite = () => {
      root.$axios
        .get("/orderSafe/platformBan/site/list")
        .then((res) => {
          siteList.value = res.data;
        })
    }
    getSite()
    const reset = () => {
      params.site_id = 19
      params.goods_id = ""
      params.page = 1
      params.limit = 10
      params.total = 0
      getList()
    }
    const del = async data=>{
      const confirm = await root.$goDialog('是否确定删除？')
      if(!confirm) return
      root.$axios.post('/orderSafe/platformBan/goodsWhite/delete', {
        id: data.id
      })
      .then(res=>{
        root.$message.success(res.msg)
        getList()
      })
    }
    const save = () => {
      if(!info.site_id){
        root.$message.warning('请选择站点')
        return
      }
      if(!info.goods_id) {
        root.$message.warning('请输入原站商品ID')
        return
      }
      if(info.goods_link && !info.goods_link.includes('http://') && !info.goods_link.includes('https://')) {
        root.$message.warning('请输入正确地址格式')
        return
      }
      root.$axios.post(`/orderSafe/platformBan/goodsWhite/add`, info)
      .then(res=>{
        root.$message.success(res.msg)
        getList()
        addVisible.value = false
      })
    }
    const handleSizeChange = (e) => {
      params.limit = e;
      getList();
    };
    const handleCurrentChange = (e) => {
      params.page = e;
      getList();
    };

    return {
      addVisible,
      siteList,
      params,
      info,
      state,
      list,
      header,
      del,
      save,
      reset,
      getList,
      handleSizeChange,
      handleCurrentChange
    }
  },
})
</script>
<style lang="scss" scoped>
.flex-item{
  width: 60%;
  margin-top: 0;
  &>div{
    margin-right: 15px;
  }
}
.item{
  display: flex;
  margin-top: 15px;
  &>span{
    width: 90px;
    flex-shrink: 0;
  }
}
</style>